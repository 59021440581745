import { ThemeOptions } from '@mui/material';

const SELECT: ThemeOptions = {
  components: {
    MuiSelect: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
          textTransform: 'none',
          '& > .MuiSelect-icon': {
            top: 'calc(45% - .5em)',
          },
          '&:hover > .MuiOutlinedInput-notchedOutline': {
            borderColor: '#CDD0D1',
          },
        },
      },
      defaultProps: {
        MenuProps: {
          PaperProps: {
            sx: {
              backgroundColor: '#fff',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            },
          },
        },
      },
    },
  },
};

export default SELECT;
