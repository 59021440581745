import React, { Suspense } from 'react';

import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Navigate, Route, Routes } from 'react-router-dom';

import Header from './components/layout/Header';
import LoadingPageWrapper from './components/shared/LoadingPageWrapper';

const PropertyManagementLazyPage = React.lazy(
  () => import('./pages/PropertyManagementPage'),
);
const AllocationLazyPage = React.lazy(() => import('./pages/AllocationPage'));
const ModelSetupLazyPage = React.lazy(() => import('./pages/ModelSetupPage'));
const ReportingPage = React.lazy(() => import('./pages/ReportingPage'));
const ChangeLogLazyPage = React.lazy(() => import('./pages/ChangeLogPage'));

function App() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Revantage</title>
      </Helmet>
      <Header />
      <Routes>
        <Route path="/" element={<Navigate to="/property-management" />} />
        <Route
          path="/property-management"
          element={
            <Suspense
              fallback={<LoadingPageWrapper pageName="Property Management" />}
            >
              <PropertyManagementLazyPage />
            </Suspense>
          }
        />
        <Route
          path="/allocation"
          element={
            <Suspense fallback={<LoadingPageWrapper pageName="Allocation" />}>
              <AllocationLazyPage />
            </Suspense>
          }
        />
        <Route
          path="/model-setup"
          element={
            <Suspense fallback={<LoadingPageWrapper pageName="Model Setup" />}>
              <ModelSetupLazyPage />
            </Suspense>
          }
        />
        <Route
          path="/reporting"
          element={
            <Suspense fallback={<LoadingPageWrapper pageName="Reporting" />}>
              <ReportingPage />
            </Suspense>
          }
        />
        <Route
          path="/change-log"
          element={
            <Suspense fallback={<LoadingPageWrapper pageName="Change Log" />}>
              <ChangeLogLazyPage />
            </Suspense>
          }
        />
      </Routes>
    </HelmetProvider>
  );
}

export default App;
