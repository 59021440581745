import { ThemeOptions } from '@mui/material';

const BUTTON: ThemeOptions = {
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
          textTransform: 'none',
          paddingTop: 15,
          paddingBottom: 15,
        },
        contained: {
          backgroundColor: '#009DE0',
          color: '#FFFFFF',
          '&:hover': {
            backgroundColor: '#0065AC',
          },
          '&:active': {
            backgroundColor: '#003F77',
          },
          '&:focus': {
            outline: '2px solid #F1A204',
          },
          '&.Mui-disabled': {
            backgroundColor: '#E0E0E0',
            color: '#BDBDBD',
          },
        },
        outlined: {
          // Outlined Button Type
          borderColor: '#CDD0D1',
          color: '#000',
          '&:hover': {
            borderColor: '#009DE0',
            color: '#009DE0',
          },
          '&:active': {
            borderColor: '#003F77',
            color: '#003F77',
          },
          '&:focus': {
            borderColor: '#F1A204',
            color: '#0065AC',
          },
          '&.Mui-disabled': {
            borderColor: '#E0E0E0',
            color: '#BDBDBD',
          },
        },
        text: {
          // Flat Button Type
          color: '#0065AC',
          '&:hover': {
            backgroundColor: '#E3F2FD',
            color: '#009DE0',
          },
          '&:active': {
            backgroundColor: '#E1F5FE',
            color: '#003F77',
          },
          '&:focus': {
            color: '#0065AC',
            outline: '2px solid #F1A204',
          },
          '&.Mui-disabled': {
            color: '#BDBDBD',
          },
        },
      },
      defaultProps: {
        disableElevation: true,
      },
    },
  },
};

export default BUTTON;
