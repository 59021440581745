const PALETTE = {
  palette: {
    primary: {
      50: '#F0FCFF',
      100: '#CCF0FF',
      200: '#9FE0FF',
      300: '#76D3FF',
      400: '#3BB8F0',
      500: '#009DE0', // Main color
      600: '#0065AC',
      700: '#003F77',
      900: '#001538', // Darkest shade
    },
    secondary: {
      main: '#4C49F2', // Main secondary color (from the link hover color in the image)
    },
    success: {
      50: '#F5FDF4',
      100: '#EBFBEA',
      200: '#C9F4CA',
      300: '#94EA98',
      400: '#5FDF65',
      500: '#29D445', // Main success color
      600: '#23B43B',
      700: '#1B8A2D',
      900: '#0E4A18',
    },
    warning: {
      50: '#FFFCF2',
      100: '#FFF8E5',
      200: '#FFEDBF',
      300: '#FFDC80',
      400: '#FDC749',
      500: '#FFB300', // Main warning color
      600: '#D68F00',
      700: '#A66400',
      900: '#593100',
    },
    error: {
      50: '#FFF4F2',
      100: '#FEDDD5',
      200: '#FBB7AA',
      300: '#F38377',
      400: '#E34F4A',
      500: '#D40000', // Main error color
      600: '#B40000',
      700: '#8A0000',
      900: '#4A0000',
    },
    blackWhite: {
      white: '#FFFFFF',
      50: '#F4F5F5',
      100: '#E5E6E7',
      200: '#CDD0D1',
      300: '#9A9FA2',
      400: '#6C7275',
      500: '#3D4142', // Midpoint gray
      600: '#2F3132',
      700: '#161718',
      black: '#000000',
    },
    background: {
      default: '#F9FAFB', // BG1
      paper: '#EEF2F6', // BG2
    },
    link: {
      main: '#4C49F2', // Main link color
      hover: '#5C06CA', // Hover link color
    },
  },
};

export default PALETTE;
