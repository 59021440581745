import { ThemeOptions } from '@mui/material';

const INPUT: ThemeOptions = {
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: '16px',
            backgroundColor: '#FFFFFF',
            '& fieldset': {
              borderColor: '#fff',
            },
            '&:hover fieldset': {
              borderColor: '#CDD0D1',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#0065AC',
            },
            '&.Mui-error fieldset': {
              borderColor: '#D32F2F',
            },
            '&.Mui-disabled fieldset': {
              borderColor: '#E0E0E0',
            },
            '& input': {
              p: 3.5,
            },
            '&.Mui-disabled input': {
              color: '#BDBDBD',
            },
          },
          '& .MuiInputLabel-root': {
            fontSize: '16px',
            '&.Mui-focused': {
              color: '#0065AC',
            },
            '&.Mui-error': {
              color: '#D32F2F',
            },
            '&.Mui-disabled': {
              color: '#BDBDBD',
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          backgroundColor: '#FFFFFF',
          '& fieldset': {
            borderColor: '#fff',
          },
          '&:hover fieldset': {
            borderColor: '#CDD0D1',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#0065AC',
          },
          '&.Mui-error fieldset': {
            borderColor: '#D32F2F',
          },
          '&.Mui-disabled fieldset': {
            borderColor: '#E0E0E0',
          },
          '& input': {
            p: 3.5,
          },
          '&.Mui-disabled input': {
            color: '#BDBDBD',
          },
        },
        input: {
          p: 3.5,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '16px',
          '&.Mui-focused': {
            color: '#0065AC',
          },
          '&.Mui-error': {
            color: '#D32F2F',
          },
          '&.Mui-disabled': {
            color: '#BDBDBD',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '12px',
          color: '#D32F2F',
          '&.Mui-disabled': {
            color: '#BDBDBD',
          },
        },
      },
    },
  },
};

export default INPUT;
