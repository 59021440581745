import { ThemeOptions } from '@mui/material';

const SWITCH: ThemeOptions = {
  components: {
    MuiSwitch: {
      styleOverrides: {
        root: {
          disableRipple: true,
        },
        switchBase: {
          boxShadow: 'none',
          color: '#C4C4C4',
          '&:hover + .MuiSwitch-track': {
            opacity: 1,
          },
          '&.Mui-checked': {
            color: '#009DE0',
            boxShadow: 'none',
          },
          '&.Mui-checked + .MuiSwitch-track': {
            backgroundColor: '#fff',
            border: '2px solid #009DE0',
            opacity: 0.7,
          },
          '& + .MuiSwitch-track': {
            border: '2px solid #C4C4C4',
            backgroundColor: '#fff',
            opacity: 0.7,
          },
          '& .MuiSwitch-thumb': {
            boxShadow: 'none',
            backgroundColor: '#fff',
            border: '2px solid #C4C4C4',
          },
          '&.Mui-checked .MuiSwitch-thumb': {
            boxShadow: 'none',
            backgroundColor: '#009DE0',
            border: '2px solid #009DE0',
          },
          '&.Mui-disabled': {
            color: '#e5e5eb',
            '& + .MuiSwitch-track': {
              border: '2px solid #C4C4C4',
              opacity: 0.5,
            },
            '&.Mui-checked + .MuiSwitch-track': {
              border: '2px solid #76D3FF',
            },
          },
        },
      },
    },
  },
};

export default SWITCH;
