import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CircularProgress } from '@mui/material';

const loadingWrapperStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 3,
  height: '100vh',
  overflow: 'hidden',
};

interface Props {
  pageName: string;
}

const LoadingPageWrapper: React.FC<Props> = ({ pageName }) => (
  <Box sx={loadingWrapperStyles}>
    <CircularProgress size={70} sx={{ color: 'blackWhite.500' }} />
    <Typography variant="bgMid" sx={{ color: 'blackWhite.400' }}>
      {pageName} Page is loading...
    </Typography>
  </Box>
);

export default LoadingPageWrapper;
