import { ThemeOptions } from '@mui/material';

const PAPER: ThemeOptions = {
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          marginTop: 1,
          borderRadius: '16px',
          boxShadow: '0px 3.8px 9.6px 0px rgba(0, 0, 0, 0.08)',
        },
      },
    },
  },
};

export default PAPER;
