import { ThemeOptions } from '@mui/material';
import { TypographyVariantsOptions } from '@mui/material/styles';

export { asapFontFamily as fontFamily };

const asapFontFamily = 'Asap, Arial, sans-serif';

export const typographySchema: Partial<TypographyVariantsOptions> = {
  fontFamily: asapFontFamily,
  htmlFontSize: 10,
  fontSize: 18,
  h1: {
    fontFamily: asapFontFamily,
    fontWeight: 400, // Regular
    fontSize: '72px', // Large heading size
    lineHeight: '96px',
    letterSpacing: '-1%',
  },
  h1Bold: {
    fontFamily: asapFontFamily,
    fontWeight: 700, // Bold
    fontSize: '72px',
    lineHeight: '96px',
    letterSpacing: '-1%',
  },
  h2: {
    fontFamily: asapFontFamily,
    fontWeight: 400,
    fontSize: '48px',
    lineHeight: '64px',
    letterSpacing: '-1%',
  },
  h2Bold: {
    fontFamily: asapFontFamily,
    fontWeight: 700,
    fontSize: '48px',
    lineHeight: '64px',
    letterSpacing: '-1%',
  },
  h3: {
    fontFamily: asapFontFamily,
    fontWeight: 400,
    fontSize: '40px',
    lineHeight: '48px',
    letterSpacing: '-1%',
  },
  h3Bold: {
    fontFamily: asapFontFamily,
    fontWeight: 700,
    fontSize: '40px',
    lineHeight: '48px',
    letterSpacing: '-1%',
  },
  h4: {
    fontFamily: asapFontFamily,
    fontWeight: 400,
    fontSize: '32px',
    lineHeight: '48px',
    letterSpacing: '-1%',
  },
  h4Bold: {
    fontFamily: asapFontFamily,
    fontWeight: 700,
    fontSize: '32px',
    lineHeight: '48px',
    letterSpacing: '-1%',
  },
  h5: {
    fontFamily: asapFontFamily,
    fontWeight: 400,
    fontSize: '24px',
    lineHeight: '40px',
    letterSpacing: '-1%',
  },
  h5Bold: {
    fontFamily: asapFontFamily,
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '40px',
    letterSpacing: '-1%',
  },
  caption: {
    fontFamily: asapFontFamily,
    fontWeight: 400,
    fontSize: '14px', // Smaller caption
    lineHeight: '24px',
    letterSpacing: '5%',
  },
  captionBold: {
    fontFamily: asapFontFamily,
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '5%',
  },
  lead: {
    fontFamily: asapFontFamily,
    fontWeight: 400,
    fontSize: '18px', // Slightly larger than body
    lineHeight: '32px',
    letterSpacing: '-1%',
  },
  leadBold: {
    fontFamily: asapFontFamily,
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '32px',
    letterSpacing: '-1%',
  },
  body1: {
    fontFamily: asapFontFamily,
    fontWeight: 400,
    fontSize: '15px', // Regular body text
    lineHeight: '24px',
    letterSpacing: '-1%',
  },
  body1Bold: {
    fontFamily: asapFontFamily,
    fontWeight: 700,
    fontSize: '15px', // Bold variant for body
    lineHeight: '24px',
    letterSpacing: '-1%',
  },
  body1Italic: {
    fontFamily: asapFontFamily,
    fontWeight: 400,
    fontStyle: 'italic',
    fontSize: '15px', // Italic variant for body
    lineHeight: '24px',
    letterSpacing: '-1%',
  },
  body1Underline: {
    fontFamily: asapFontFamily,
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '24px',
    textDecoration: 'underline',
    letterSpacing: '-1%',
  },
  bodyUppercase: {
    fontFamily: asapFontFamily,
    fontWeight: 400,
    textTransform: 'uppercase',
    fontSize: '15px',
    lineHeight: '24px',
    letterSpacing: '-1%',
  },
  small: {
    fontFamily: asapFontFamily,
    fontWeight: 400,
    fontSize: '13px', // Smaller text
    lineHeight: '16px',
    letterSpacing: '-1%',
  },
  smallBold: {
    fontFamily: asapFontFamily,
    fontWeight: 700,
    fontSize: '13px',
    lineHeight: '16px',
    letterSpacing: '-1%',
  },
  smallItalic: {
    fontFamily: asapFontFamily,
    fontWeight: 400,
    fontStyle: 'italic',
    fontSize: '13px',
    lineHeight: '16px',
    letterSpacing: '-1%',
  },
  smallUnderline: {
    fontFamily: asapFontFamily,
    fontWeight: 400,
    textDecoration: 'underline',
    fontSize: '13px',
    lineHeight: '16px',
    letterSpacing: '-1%',
  },
  smallUppercase: {
    fontFamily: asapFontFamily,
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '16px',
    letterSpacing: '-1%',
    textTransform: 'uppercase',
  },
  tiny: {
    fontFamily: asapFontFamily,
    fontWeight: 400,
    fontSize: '11px', // Tiny text size
    lineHeight: '12px',
    letterSpacing: '-1%',
  },
  tinyBold: {
    fontFamily: asapFontFamily,
    fontWeight: 700,
    fontSize: '11px',
    lineHeight: '12px',
    letterSpacing: '-1%',
  },
  tinyItalic: {
    fontFamily: asapFontFamily,
    fontWeight: 400,
    fontStyle: 'italic',
    fontSize: '11px',
    lineHeight: '12px',
    letterSpacing: '-1%',
  },
  tinyUnderline: {
    fontFamily: asapFontFamily,
    fontWeight: 400,
    textDecoration: 'underline',
    fontSize: '11px',
    lineHeight: '12px',
    letterSpacing: '-1%',
  },
  bodyNarrow: {
    fontFamily: asapFontFamily,
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '16px',
    letterSpacing: '-1%',
  },
  bodyNarrowUnderline: {
    fontFamily: asapFontFamily,
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '16px',
    letterSpacing: '-1%',
    textDecoration: 'underline',
  },
  bodyUppercaseNarrow: {
    fontFamily: asapFontFamily,
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '16px',
    letterSpacing: '-1%',
    textTransform: 'uppercase',
  },
};

const TYPOGRAPHY = <ThemeOptions>{
  typography: {
    ...typographySchema,
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1Bold: 'h1',
          h2Bold: 'h2',
          h3Bold: 'h3',
          h4Bold: 'h4',
          h5Bold: 'h5',
          h6Bold: 'h6',
        },
      },
    },
  },
};

export default TYPOGRAPHY;
