import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import App from './App.tsx';
import './index.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { THEME } from './theme/theme.ts';
import { ApolloProvider } from '@apollo/client/react';
import { InMemoryCache } from '@apollo/client/cache';
import { ApolloClient } from '@apollo/client/core';

const root = createRoot(document.getElementById('root') as HTMLElement);

const router = createBrowserRouter([
  {
    path: '*',
    element: <App />,
  },
]);

const graphqlClient = new ApolloClient({
  uri: import.meta.env.VITE_GRAPHQL_CLIENT_URI,
  cache: new InMemoryCache({
    typePolicies: {
      revantage_query: {
        merge: true,
      },
    },
  }),
});

root.render(
  <StrictMode>
    <ApolloProvider client={graphqlClient}>
      <ThemeProvider theme={THEME}>
        <SnackbarProvider>
          <RouterProvider router={router} />
        </SnackbarProvider>
      </ThemeProvider>
    </ApolloProvider>
  </StrictMode>,
);
