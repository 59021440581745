'use client';

import { createTheme } from '@mui/material';

import BASE from './base';
import BUTTON from './button';
import INPUT from './input';
import PALETTE from './palette';
import PAPER from './paper';
import SELECT from './select';
import SWITCH from './switch';
import TYPOGRAPHY from './typography';

export const THEME = createTheme(
  PALETTE,
  TYPOGRAPHY,
  BASE,
  BUTTON,
  INPUT,
  SELECT,
  PAPER,
  SWITCH,
);
